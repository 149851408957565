import { APP_PRIVILEGES, UserType } from "redux/types/DataTypes";

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  isOpen?: boolean;
  icon?: string;
  img?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  isActive?: boolean;
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
  requiredUserType?: UserType | UserType[] | "*";
  strictPermissionCheck?: boolean;
  requiredPrivileges?: string[];
};

const MENU_ITEMS: MenuItemType[] = [
  // { key: 'navigation', label: 'Navigation', isTitle: true },
  // {
  //     key: 'dashboards',
  //     label: 'Dashboards',
  //     isTitle: false,
  //     icon: 'uil-home-alt',
  //     badge: { variant: 'success', text: '4' },
  //     children: [
  //         {
  //             key: 'ds-analytics',
  //             label: 'Analytics',
  //             url: '/dashboard/analytics',
  //             parentKey: 'dashboards',
  //         },
  //         {
  //             key: 'ds-ecommerce',
  //             label: 'Ecommerce',
  //             url: '/dashboard/ecommerce',
  //             parentKey: 'dashboards',
  //         },
  //         {
  //             key: 'ds-project',
  //             label: 'Projects',
  //             url: '/dashboard/project',
  //             parentKey: 'dashboards',
  //         },
  //         {
  //             key: 'ds-ewallet',
  //             label: 'E-Wallet',
  //             url: '/dashboard/e-wallet',
  //             parentKey: 'dashboards',
  //             badge: { variant: 'danger', text: 'New' },
  //         },
  //     ],
  // },
  // { key: "apps", label: "Pages", isTitle: true },
  // {
  //   key: "dashboard",
  //   label: "Dashboard",
  //   icon: "mdi mdi-view-dashboard",
  //   url: "/auth",
  //   requiredUserType: [
  //     UserType.EMPLOYEE,
  //     UserType.CUSTOMER,
  //     UserType.VENDOR,
  //     UserType.TRANSPORTER,
  //     UserType.SUPPLIER,
  //   ],
  // },
  // {
  //   key: "project",
  //   label: "Project",
  //   isTitle: false,
  //   icon: "uil-store",
  //   url: "/auth/projectV2",
  // },
  {
    key: "project",
    label: "Project",
    isTitle: false,
    icon: "uil-store",
    url: "/auth/project/list",
    // requiredUserType: [UserType.EMPLOYEE, UserType.CUSTOMER],
    // requiredPrivileges: [APP_PRIVILEGES.May_Access_Project_List.key],
  },
  {
    key: 'purchase',
    label: 'Purchase',
    isTitle: false,
    icon: 'uil-usd-circle',
    children: [
      {
        key: "indent",
        label: "Indent",
        url: "/auth/indent/list",
        icon: 'uil-invoice',
        parentKey: "purchase",
      },
      {
        key: "rfq",
        label: "RFQ",
        url: "/auth/rfq/list",
        icon: 'uil-file-question-alt',
        parentKey: "purchase",
      },
      {
        key: "quotation",
        label: "Quotation",
        url: "/auth/quotation/list",
        icon: 'mdi mdi-comment-quote-outline',
        parentKey: "purchase",
      },
      {
        key: "po",
        label: "PO",
        url: "/auth/po/list",
        icon: 'uil-notes',
        parentKey: "purchase",
      },
      {
        key: "gate-entry",
        label: "Gate Entry",
        url: "/auth/gate-entry/list",
        icon: 'mdi mdi-location-enter',
        parentKey: "purchase",
      },
      {
        key: "grn",
        label: "GRN",
        url: "/auth/grn/list",
        icon: 'uil-shop',
        parentKey: "purchase",
      }
    ],
    requiredUserType: UserType.EMPLOYEE
  },
  {
    key: 'inventory-section',
    label: 'Inventory',
    isTitle: false,
    icon: 'uil-package',
    children: [
      {
        key: "gin",
        label: "GIN",
        url: "/auth/gin/list",
        icon: "uil-store",
        parentKey: "inventory-section",
      },
      {
        key: "warehouse",
        label: "Warehouse",
        url: "/auth/warehouse/list",
        icon: "mdi mdi-warehouse",
        parentKey: "inventory-section",
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Warehouse_List.key],
      },
      {
        key: "inventory",
        label: "Inventory",
        url: "/auth/inventory/list",
        icon: "uil-package",
        parentKey: "inventory-section",
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Warehouse_List.key],
      },
      {
        key: "inventory-history",
        label: "Inventory History",
        url: "/auth/inventory-history/list",
        icon: "uil-history",
        parentKey: "inventory-section",
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Warehouse_List.key],
      }
    ],
    requiredUserType: UserType.EMPLOYEE
  },
 
  {
    key: "alerts",
    label: "Alerts",
    isTitle: false,
    // isOpen: true,
    icon: "mdi mdi-alert-outline",
    children: [
      {
        key: "approval",
        label: "Approvals",
        icon: "uil-check-circle",
        url: "/auth/approvalV2/list",
        parentKey: "alerts",
        // requiredUserType: UserType.EMPLOYEE,
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Approval_List.key],
      }
    ]
  },
  {
    key: "item-category",
    label: "Item Category",
    url: "/auth/item-categories/list",
    icon: "uil-sitemap"
  },
  {
    key: "item",
    label: "Item",
    url: "/auth/item/list",
    icon: "uil-dice-one"
  },
  
  {
    key: "processes",
    label: "Processes",
    url: "/auth/processes/list",
    icon: "mdi mdi-layers-triple"
  },
  {
    key: "vendor",
    label: "Vendor",
    isTitle: false,
    icon: "uil-store",
    url: "/auth/vendor",
  },
  {
    key: "organization",
    label: "Account",
    icon: "mdi mdi-office-building",
    url: "/auth/organization",
    requiredUserType: UserType.EMPLOYEE,
    // requiredPrivileges: [APP_PRIVILEGES.Organization_Listing.key],
    // parentKey: "archive"
  },
  {
    key: "customer",
    label: "Contact",
    icon: "mdi mdi-account-group-outline",
    url: "/auth/customer",
    requiredUserType: UserType.EMPLOYEE,
    // requiredPrivileges: [APP_PRIVILEGES.Customer_Listing.key],
    // parentKey: "archive"
  },
  {
    key: "setting",
    label: "Settings",
    isTitle: false,
    icon: "uil-bright",
    children: [
      {
        key: "team",
        label: "Teams",
        url: "/auth/team",
        icon: "uil-users-alt",
        parentKey: "setting",
        requiredPrivileges: [APP_PRIVILEGES.May_Access_Team_List.key],
      },
      {
        key: "employee",
        label: "Employees",
        url: "/auth/employee",
        icon: "mdi mdi-account-tie-outline",
        parentKey: "setting",
        requiredPrivileges: [APP_PRIVILEGES.May_Access_Employee_List.key],
      },
      // {
      //   key: "product",
      //   label: "Product",
      //   url: "/auth/product",
      //   icon: "mdi mdi-cube-outline",
      //   parentKey: "setting",
      //   requiredPrivileges: [APP_PRIVILEGES.May_Access_Product_List.key],
      // },

      // {
      //   key: "configuration",
      //   label: "Configurations",
      //   url: "/",
      //   icon: 'uil-file-edit-alt',
      //   parentKey: "setting"
      // },

      {
        key: "role",
        label: "Roles",
        icon: "uil-user",
        url: "/auth/role",
        parentKey: "setting",
        requiredUserType: UserType.EMPLOYEE,
        requiredPrivileges: [APP_PRIVILEGES.May_Access_Roles_List.key],
      },
      {
        key: "approvalV2",
        label: "Approvals Master",
        url: "/auth/approvalV2/masterList",
        icon: "uil-check-circle",
        parentKey: "setting"
      },
    ],
    requiredUserType: UserType.EMPLOYEE,
  },
  {
    key: 'archive',
    label: 'Archive',
    isTitle: false,
    icon: 'mdi mdi-briefcase-clock-outline',
    children: [
      {
        key: "locate-leads",
        label: "Locate Leads",
        icon: "mdi mdi-account-filter-outline",
        url: "/auth/locate-leads",
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Query.key],
        parentKey: "archive"
      },
      // {
      //   key: "project",
      //   label: "Projects",
      //   icon: "mdi mdi-folder-multiple-outline",
      //   url: "/auth/project/list",
      //   // requiredUserType: [UserType.EMPLOYEE, UserType.CUSTOMER],
      //   // requiredPrivileges: [APP_PRIVILEGES.May_Access_Project_List.key],
      //   parentKey: "archive"
      // },
      {
        key: "contact",
        label: "Contacts",
        icon: "mdi mdi-folder-multiple-outline",
        url: "/auth/contact/list",
        requiredUserType: [UserType.EMPLOYEE, UserType.CUSTOMER],
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Project_List.key]
        parentKey: "archive"
      },
      {
        key: "zoho-vendor",
        label: "Zoho-Vendors",
        icon: "mdi mdi-all-inclusive",
        url: "/auth/zoho-vendor/list",
        requiredUserType: [UserType.EMPLOYEE, UserType.CUSTOMER],
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Project_List.key]
        parentKey: "archive"
      },
      {
        key: "customer-quotation",
        label: "Customer Quotation",
        url: "/auth/customer-quotation/list",
        icon: "uil-book-reader",
        parentKey: "archive"
      },
      {
        key: "machine",
        label: "Machine",
        url: "/auth/machine/list",
        icon: "mdi mdi-robot-industrial",
        // requiredPrivileges: [APP_PRIVILEGES.May_Access_Machine_List.key],
        parentKey: "archive"
      },
      {
        key: "myjob",
        label: "Job",
        isTitle: false,
        icon: "mdi mdi-truck-outline",
        url: "/auth/job",
        requiredUserType: [
          UserType.VENDOR,
          UserType.TRANSPORTER,
          UserType.SUPPLIER,
        ],
        strictPermissionCheck: true,
        requiredPrivileges: [APP_PRIVILEGES.May_Access_Job_List.key],
        parentKey: "archive"
      },
      {
        key: "my-iot",
        label: "IOT",
        isTitle: false,
        icon: "mdi mdi-remote",
        url: "/auth/iot-stats",
        requiredUserType: [UserType.VENDOR],
        parentKey: "archive"
      },
      {
        key: "alerts",
        label: "Alerts",
        isTitle: false,
        // isOpen: true,
        icon: "mdi mdi-alert-outline",
        children: [
          {
            key: "approval",
            label: "Approval",
            icon: "uil-check-circle",
            url: "/auth/approval",
            parentKey: "alerts",
            requiredUserType: UserType.EMPLOYEE,
            // requiredPrivileges: [APP_PRIVILEGES.May_Access_Approval_List.key],
          },
    
          {
            key: "task",
            label: "Task",
            icon: "uil-file-alt",
            url: "/auth/task",
            parentKey: "alerts",
            requiredUserType: UserType.EMPLOYEE,
            // requiredPrivileges: [APP_PRIVILEGES.May_Access_Task_List.key],
          },
    
          {
            key: "job",
            label: "Job",
            icon: "uil-suitcase",
            url: "/auth/job",
            parentKey: "alerts",
            requiredUserType: UserType.EMPLOYEE,
            requiredPrivileges: [APP_PRIVILEGES.May_Access_Job_List.key],
          },
    
          {
            key: "chat",
            label: "Chat",
            icon: "uil-comment",
            url: "/auth/chatList",
            parentKey: "alerts",
            requiredUserType: UserType.EMPLOYEE,
          },
        ],
        parentKey: "archive"
      },
      {
        key: "report",
        label: "Reports",
        isTitle: false,
        icon: "uil uil-chart-growth sizesm",
        children: [
          {
            key: "project-report",
            label: "Project",
            url: "/auth/reports/projectReport",
            icon: "mdi mdi-folder-multiple-outline",
            parentKey: "report",
            requiredPrivileges: [APP_PRIVILEGES.May_Access_Employee_List.key],
          },
        ],
        requiredUserType: UserType.EMPLOYEE,
        parentKey: "archive"
      },
      {
        key: "setting",
        label: "Settings",
        isTitle: false,
        icon: "uil-bright",
        children: [
          
          {
            key: "product",
            label: "Product",
            url: "/auth/product",
            icon: "mdi mdi-cube-outline",
            parentKey: "setting",
            requiredPrivileges: [APP_PRIVILEGES.May_Access_Product_List.key],
          },
          
    
          // {
          //   key: "configuration",
          //   label: "Configurations",
          //   url: "/",
          //   icon: 'uil-file-edit-alt',
          //   parentKey: "setting"
          // },
    
          // {
          //   key: "role",
          //   label: "Roles",
          //   icon: "uil-user",
          //   url: "/auth/role",
          //   parentKey: "setting",
          //   requiredUserType: UserType.EMPLOYEE,
          //   requiredPrivileges: [APP_PRIVILEGES.May_Access_Roles_List.key],
          // },
        ],

        requiredUserType: UserType.EMPLOYEE,
        parentKey: "archive"
      },
      {
        key: 'quality-section',
        label: 'Quality',
        isTitle: false,
        icon: 'mdi mdi-briefcase-clock-outline',
        children: [
          {
            key: "iqc-equipment",
            label: "IQC Equipment",
            url: "/auth/iqc-equipment/list",
            icon: "mdi mdi-state-machine",
            parentKey: "quality-section"
          },
          {
            key: "iqc-parameter",
            label: "IQC Parameter",
            url: "/auth/iqc-parameter/list",
            icon: "mdi mdi-state-machine",
            parentKey: "quality-section"
          },
          {
            key: "iqc-quality",
            label: "Sample Quality Master",
            url: "/auth/iqc-quality/list",
            icon: "mdi mdi-state-machine",
            parentKey: "quality-section"
          }
        ],
        requiredUserType: UserType.EMPLOYEE,
    parentKey: "archive"
      },
    
      {
        key: "manage",
        label: "Manage",
        isTitle: false,
        icon: "mdi mdi-briefcase-clock-outline",
        children: [
          {
            key: "forms",
            label: "Forms",
            icon: "uil-copy-alt",
            url: "/auth/form",
            parentKey: "manage",
            requiredUserType: UserType.EMPLOYEE,
          },
          {
            key: "tags",
            label: "Tags",
            url: "/auth/tag",
            icon: "uil-tag",
            parentKey: "manage",
          },
          {
            key: "trigger",
            label: "Trigger",
            url: "/auth/trigger",
            icon: "mdi mdi-lightning-bolt-outline",
            parentKey: "manage",
          }
        ],
        requiredUserType: UserType.EMPLOYEE,
        parentKey: "archive"
      },
      {
        key: "raw-material",
        label: "Raw Material",
        url: "/auth/raw-material",
        icon: " uil-screw",
        parentKey: "archive"
      },
      {
        key: "supplier",
        label: "Supplier",
        icon: "mdi mdi-dolly",
        url: "/auth/supplier",
        parentKey: "archive"
      },
      {
        key: "transporter",
        label: "Transporter",
        icon: "uil-truck",
        url: "/auth/transporter",
        parentKey: "archive"
      },
      {
        key: "leads",
        label: "Leads",
        icon: "mdi mdi-account-arrow-right-outline",
        url: "/auth/leads",
        parentKey: "archive"
      },
      {
        key: "myenquiry",
        label: "My Enquiry",
        isTitle: false,
        icon: "mdi mdi-information-outline",
        url: "/auth/myenquiry",
        parentKey: "archive"
      },
      {
        key: "enquiry",
        label: "Enquiry",
        isTitle: false,
        icon: "mdi mdi-folder-alert-outline",
        url: "/auth/enquiry",
        parentKey: "archive"
      }
    ],
    requiredUserType: UserType.EMPLOYEE
  },
];

export { MENU_ITEMS };
