import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PaymentActionTypes } from './constants';
import {
  createPaymentInvoice,
  createPayment,
  fetchPayments,
  fetchMilestones,
  fetchPaymentById,
  editPayment,
  fetchOutgoingPayments,
} from 'helpers/api/payment';
import {
  createPaymentSuccess,
  createPaymentFailure,
  fetchPaymentsSuccess,
  fetchPaymentsFailure,
  fetchMilestonesSuccess,
  fetchMilestonesFailure,
  fetchPaymentByIdSuccess,
  fetchPaymentByIdFailure,
  editPaymentSuccess,
  editPaymentFailure,
  createPaymentInvoiceSuccess,
  createPaymentInvoiceFailure,
  fetchOutgoingPaymentsSuccess,
  fetchOutgoingPaymentsFailure,
} from './actions';

function* fetchPaymentsSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchPayments, action.payload);
    const paymentsData = response.data; 
    yield put(fetchPaymentsSuccess(paymentsData));
  } catch (e: any) {
    yield put(fetchPaymentsFailure(e));
  }
}

function* fetchOutgoingPaymentsSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchOutgoingPayments, action.payload);
    const paymentsData = response.data; 
    yield put(fetchOutgoingPaymentsSuccess(paymentsData));
  } catch (e: any) {
    yield put(fetchOutgoingPaymentsFailure(e));
  }
}

function* fetchMilestonesSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchMilestones);
    const milestonesData = response.data; 
    yield put(fetchMilestonesSuccess(milestonesData));
  } catch (e: any) {
    yield put(fetchMilestonesFailure(e));
  }
}

function* createPaymentSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createPayment, data);
    const paymentData = response.data;
    yield put(createPaymentSuccess(paymentData));
  } catch (e: any) {
    yield put(createPaymentFailure(e));
  }
}

function* createPaymentInvoiceSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createPaymentInvoice, data);
    const paymentData = response.data;
    yield put(createPaymentInvoiceSuccess(paymentData));
  } catch (e: any) {
    yield put(createPaymentInvoiceFailure(e));
  }
}

function* fetchPaymentByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchPaymentById, action.payload);
    const paymentData = response.data;
    yield put(fetchPaymentByIdSuccess(paymentData));
  } catch (e: any) {
    yield put(fetchPaymentByIdFailure(e));
  }
}

function* editPaymentSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editPayment, id, data);
    const paymentData = response.data;
    yield put(editPaymentSuccess(paymentData));
    yield put({ type: PaymentActionTypes.FETCH_PAYMENT_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editPaymentFailure(e));
  }
}

function* watchPayments() {
  yield takeEvery(PaymentActionTypes.FETCH_PAYMENTS_REQUEST, fetchPaymentsSaga);
  yield takeEvery(PaymentActionTypes.FETCH_OUTGOING_PAYMENTS_REQUEST, fetchOutgoingPaymentsSaga);
  yield takeEvery(PaymentActionTypes.FETCH_MILESTONES_REQUEST, fetchMilestonesSaga);
  yield takeEvery(PaymentActionTypes.CREATE_PAYMENT_REQUEST, createPaymentSaga);
  yield takeEvery(PaymentActionTypes.CREATE_PAYMENT_INVOICE_REQUEST, createPaymentInvoiceSaga);
  yield takeEvery(PaymentActionTypes.FETCH_PAYMENT_BY_ID_REQUEST, fetchPaymentByIdSaga);
  yield takeEvery(PaymentActionTypes.EDIT_PAYMENT_REQUEST, editPaymentSaga);
}

export default watchPayments;
