export enum ProjectV2ActionTypes {
    CREATE_PROJECTV2_REQUEST = 'CREATE_PROJECTV2_REQUEST',
    CREATE_PROJECTV2_SUCCESS = 'CREATE_PROJECTV2_SUCCESS',
    CREATE_PROJECTV2_FAILURE = 'CREATE_PROJECTV2_FAILURE',
    FETCH_PROJECTV2S_REQUEST = 'FETCH_PROJECTV2S_REQUEST',
    FETCH_PROJECTV2S_SUCCESS = 'FETCH_PROJECTV2S_SUCCESS',
    FETCH_PROJECTV2S_FAILURE = 'FETCH_PROJECTV2S_FAILURE',
    FETCH_PROJECTV2_BY_ID_REQUEST = 'FETCH_PROJECTV2_BY_ID_REQUEST',
    FETCH_PROJECTV2_BY_ID_SUCCESS = 'FETCH_PROJECTV2_BY_ID_SUCCESS',
    FETCH_PROJECTV2_BY_ID_FAILURE = 'FETCH_PROJECTV2_BY_ID_FAILURE',
    EDIT_PROJECTV2_REQUEST = 'EDIT_PROJECTV2_REQUEST',
    EDIT_PROJECTV2_SUCCESS = 'EDIT_PROJECTV2_SUCCESS',
    EDIT_PROJECTV2_FAILURE = 'EDIT_PROJECTV2_FAILURE',
    RESET_PROJECTV2_SUCCESS_STATE = 'RESET_PROJECTV2_SUCCESS_STATE',
    FETCH_SINGLE_PROJECTV2 = 'FETCH_SINGLE_PROJECTV2',
    SET_SINGLE_PROJECTV2 = 'SET_SINGLE_PROJECTV2',
    RESET_SINGLE_PROJECTV2 = 'RESET_SINGLE_PROJECTV2'
}
