import { APICore } from "./apiCore";
const api = new APICore();

function createIndent(data: any) {
    const baseUrl = `/indent/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchIndents(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string, status?: string, origin?: string) {
    const baseURL = `/indent/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    if (status) params.append('status', status);
    if (origin) params.append('origin', origin);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchIndentById(id: number) {
    const url = `/indent/${id}`;
    return api.get(url, "");
}

function fetchIndentByProjectId(id: number) {
    const url = `/indent/project/${id}`;
    return api.get(url, "");
}

function editIndent(id: number, data: any) {
    const url = `/indent/${id}`;
    return api.update(url, data);
}


async function createIndentWithFiles(params: { indentData: any, imageData?: any, indentItemImageData?:any }) {
    try {
        const baseUrl = '/indent';

        const createdIndent = await api.create(baseUrl, { ...params.indentData });

        const indentResponse = createdIndent.data;

        params.indentData?.item_details?.forEach(async (item: any) => {
            await updateIndentItemWithFile({id:indentResponse?.data?.newIndent?.id, indentData:item, imageData:item.files})
        });

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Indent');
            params.imageData.append('typeId', String(indentResponse?.data?.newIndent?.id));
            // params.imageData.append('tagId', '37');

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: indentResponse?.data?.newIndent?.id,
                body: {
                    ...indentResponse?.data?.newIndent,
                    image_id: newImageDataResponse,
                }
            };

            const baseRfqUrl = `/indent/updateImage/${indentResponse?.data?.newIndent?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        

        return indentResponse;

    } catch (error) {
        console.error('Error creating indent with files:', error);
        throw error;
    }
}

async function updateIndentWithFiles(params: { id: number, indentData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'Indent');
            params.imageData.append('typeId', String(params.id));
            // params.imageData.append('tagId', "37");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.indentData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/indent/${params.id}`;
        return api.update(baseUrl, {
            ...params.indentData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating indent with files:', error);
        throw error;
    }
}
async function updateIndentItemWithFile(params: { id: number, indentData: any, imageData?:any}) {
    try {

        const baseUrl = `/indent/indentItem/${params.id}`;
        const indentItem = await api.update(baseUrl, {
            ...params.indentData,
        });

        console.log("params.indentData", params.indentData)
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let uploadResponse = null;

        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Indent Item');
            params.imageData.append('typeId', String(indentItem?.data?.data?.id));
            params.imageData.append('tagId', "53");

            const newImageFile = await api.create(fileUrl, params.imageData);
            uploadResponse = newImageFile?.data?.data?.response;

            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
            
            const combinedImageIds = [
                ...(params.indentData.image_id || []),
                ...newImageDataResponse
            ];
    
            const updatedIndentItem = await api.update(baseUrl, {
                ...indentItem?.data?.data,
                image_id: combinedImageIds, 
              });
            
            console.log(updatedIndentItem, "indentItem");
            return {
                indentItem: updatedIndentItem,
                uploadResponse
            };
        }


        console.log(indentItem, "indentItem");
        return {
            indentItem,
            uploadResponse: null
        };
    } catch (error) {
        console.error('Error updating indent with files:', error);
        throw error;
    }
}




export { createIndent, fetchIndentById, fetchIndentByProjectId, fetchIndents, editIndent, createIndentWithFiles, updateIndentWithFiles, updateIndentItemWithFile };
