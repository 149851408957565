import { APICore } from "./apiCore";
const api = new APICore();

function createPO(data: any) {
    const baseUrl = `/po/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchPOs(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/po/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchPOById(id: number) {
    const url = `/po/${id}`;
    return api.get(url, "");
}

function fetchPOByProjectId(id: number) {
    const url = `/po/project/${id}`;
    return api.get(url, "");
}

function editPO(id: number, data: any) {
    const url = `/po/${id}`;
    return api.update(url, data);
}


async function createPOWithFiles(params: { poData: any, imageData?: any }) {
    try {
        const baseUrl = '/po';

        const createdPO = await api.create(baseUrl, { ...params.poData });

        const poResponse = createdPO.data;

        params.poData?.item_details?.forEach(async (item: any) => {
            await updatePoItemWithFile({id:poResponse?.data?.newPO?.id, poData:item, imageData:item.files})
        });

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'PO');
            params.imageData.append('typeId', String(poResponse?.data?.newPO?.id));
            // params.imageData.append('tagId', "40");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.poData.image_id || []),
            ...newImageDataResponse
        ];

        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: poResponse?.data?.newPO?.id,
                body: {
                    ...poResponse?.data?.newPO,
                    image_id: combinedImageIds,
                }
            };

            const basePoUrl = `/po/updateImage/${poResponse?.data?.newPO?.id}`;
            const updatePo = await api.update(basePoUrl, { ...updatedBody.body });

            return updatePo;
        }

        return poResponse;

    } catch (error) {
        console.error('Error creating po with files:', error);
        throw error;
    }
}

async function updatePOWithFiles(params: { id: number, poData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'PO');
            params.imageData.append('typeId', String(params.id));
            // params.imageData.append('tagId', "40");

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.poData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/po/${params.id}`;
        return api.update(baseUrl, {
            ...params.poData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating po with files:', error);
        throw error;
    }
}

async function updatePoItemWithFile(params: { id: number, poData: any, imageData?:any}) {
    try {

        const baseUrl = `/po/poItem/${params.id}`;
        const poItem = await api.update(baseUrl, {
            ...params.poData,
        });

        console.log("params.poData", params.poData)
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let uploadResponse = null;

        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'Po Item');
            params.imageData.append('typeId', String(poItem?.data?.data?.id));
            params.imageData.append('tagId', "57");


            const newImageFile = await api.create(fileUrl, params.imageData);
            uploadResponse = newImageFile?.data?.data?.response;

            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));

            const combinedImageIds = [
                ...(params.poData.image_id || []),
                ...newImageDataResponse
            ];

            const updatedPoItem = await api.update(baseUrl, {
                ...poItem?.data?.data,
                image_id: combinedImageIds, 
              });

            console.log(updatedPoItem, "poItem");
            return {
                poItem: updatedPoItem,
                uploadResponse
            };
        }


        console.log(poItem, "poItem");
        return {
            poItem,
            uploadResponse: null
        };
    } catch (error) {
        console.error('Error updating PO with files:', error);
        throw error;
    }
}
async function uploadFileWithImages(poId: any, file: File) {
    try {
        const formData = new FormData();
        formData.append('poId', poId);
        formData.append('file', file);

        const response = await api.upload('/po/uploadFileWithImages', formData);

        console.log(response,"response")

        if (response?.data?.success) {
            console.log(response.data.successMsg || 'File and images uploaded successfully.');
        } else {
            console.error(response?.data?.errorMsg || 'Failed to upload file and images.');
        }
    } catch (error) {
        console.error('An error occurred while uploading the file and images:', error);
        throw error;
    }
}

export { createPO, fetchPOById, fetchPOs, editPO, createPOWithFiles, updatePOWithFiles, updatePoItemWithFile,uploadFileWithImages, fetchPOByProjectId };
