import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { GADrawingActionTypes } from './constants';
import {
  createGADrawing,
  editGADrawing,
  fetchGADrawingById,
    fetchGADrawings
} from 'helpers/api/gaDrawing';
import {
  createGADrawingFailure,
  createGADrawingRequest,
  createGADrawingSuccess,
  editGADrawingFailure,
  editGADrawingRequest,
  editGADrawingSuccess,
  fetchGADrawingByIdFailure,
  fetchGADrawingByIdRequest,
  fetchGADrawingByIdSuccess,
  fetchGADrawingsFailure,fetchGADrawingsRequest,
  fetchGADrawingsSuccess,
    resetGADrawingSuccessState,
    resetSingleGADrawing
} from './actions';

function* fetchGADrawingsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchGADrawings, page, limit , sortBy, sort, searchBy);
    const grnsData = response.data; 
    yield put(fetchGADrawingsSuccess(grnsData));
  } catch (e: any) {
    yield put(fetchGADrawingsFailure(e));
  }
}

function* createGADrawingSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createGADrawing, data);
    const grnData = response.data;
    yield put(createGADrawingSuccess(grnData));
    yield put({ type: GADrawingActionTypes.FETCH_GADrawingS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createGADrawingFailure(e));
  }
}

function* fetchGADrawingByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchGADrawingById, action.payload);
    const grnData = response.data;
    yield put(fetchGADrawingByIdSuccess(grnData));
  } catch (e: any) {
    yield put(fetchGADrawingByIdFailure(e));
  }
}

function* editGADrawingSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editGADrawing, id, data);
    const grnData = response.data;
    yield put(editGADrawingSuccess(grnData));
    yield put({ type: GADrawingActionTypes.FETCH_GADrawing_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editGADrawingFailure(e));
  }
}

function* watchGADrawings() {
  yield takeEvery(GADrawingActionTypes.FETCH_GADrawingS_REQUEST, fetchGADrawingsSaga);
  yield takeEvery(GADrawingActionTypes.CREATE_GADrawing_REQUEST, createGADrawingSaga);
  yield takeEvery(GADrawingActionTypes.FETCH_GADrawing_BY_ID_REQUEST, fetchGADrawingByIdSaga);
  yield takeEvery(GADrawingActionTypes.EDIT_GADrawing_REQUEST, editGADrawingSaga);
}

export default watchGADrawings;
