import { ProjectV2ActionTypes } from './constants';

const INIT_STATE = {
  count: 0,
  loading: true,
  projectV2: [],
  singleProjectV2: {},
  error: {},
  success: false,
};

const ProjectV2Reducer = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ProjectV2ActionTypes.CREATE_PROJECTV2_REQUEST:
    case ProjectV2ActionTypes.EDIT_PROJECTV2_REQUEST:
    case ProjectV2ActionTypes.FETCH_PROJECTV2S_REQUEST:
    case ProjectV2ActionTypes.FETCH_PROJECTV2_BY_ID_REQUEST:
    case ProjectV2ActionTypes.RESET_PROJECTV2_SUCCESS_STATE:
      return { ...state, loading: true, error: {}, success: false };

    case ProjectV2ActionTypes.SET_SINGLE_PROJECTV2:
    return { ...state , singleProjectV2: action.payload.data, loading:false }  
    case ProjectV2ActionTypes.CREATE_PROJECTV2_SUCCESS:
      return {
        ...state,
        projectV2s: [...state.projectV2, action.payload.data],
        loading: false,
        error: {},
        success: true,
      };

    case ProjectV2ActionTypes.EDIT_PROJECTV2_SUCCESS:
      const updatedProjectV2s = state.projectV2.map((projectV2: any) =>
        projectV2.id === action.payload.data.id ? action.payload.data : projectV2
      );
      return {
        ...state,
        projectV2s: updatedProjectV2s,
        loading: false,
        error: {},
        success: true,
      };

    case ProjectV2ActionTypes.FETCH_PROJECTV2S_SUCCESS:
      return {
        ...state,
        projectV2s: action.payload.data,
        loading: false,
        error: {},
        success: true,
        count: action.payload.count,
      };

    case ProjectV2ActionTypes.FETCH_PROJECTV2_BY_ID_SUCCESS:
      return {
        ...state,
        singleProjectV2: action.payload.data,
        projectV2s: [action.payload.data],
        loading: false,
        error: {},
        success: true,
      };
    case ProjectV2ActionTypes.RESET_SINGLE_PROJECTV2:
      return {
          ...state,
          singleProjectV2: null,
      };

    case ProjectV2ActionTypes.CREATE_PROJECTV2_FAILURE:
    case ProjectV2ActionTypes.EDIT_PROJECTV2_FAILURE:
    case ProjectV2ActionTypes.FETCH_PROJECTV2S_FAILURE:
    case ProjectV2ActionTypes.FETCH_PROJECTV2_BY_ID_FAILURE:
      return { ...state, error: action.payload.error, loading: false, success: false };

    default:
      return state;
  }
};

export default ProjectV2Reducer;
