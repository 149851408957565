import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ProjectV2ActionTypes } from './constants';
import {
  createProjectV2,
  fetchProjectV2s,
  fetchProjectV2ById,
  editProjectV2,
} from 'helpers/api/projectV2';
import {
  createProjectV2Success,
  createProjectV2Failure,
  fetchProjectV2sSuccess,
  fetchProjectV2sFailure,
  fetchProjectV2ByIdSuccess,
  fetchProjectV2ByIdFailure,
  editProjectV2Success,
  editProjectV2Failure,
} from './actions';

function* fetchProjectV2sSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy  } = action.payload || {};
    const response = yield call(fetchProjectV2s, page, limit , sortBy, sort, searchBy);
    const projectV2sData = response.data; 
    yield put(fetchProjectV2sSuccess(projectV2sData));
  } catch (e: any) {
    yield put(fetchProjectV2sFailure(e));
  }
}

function* createProjectV2Saga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createProjectV2, data);
    const projectV2Data = response.data;
    yield put(createProjectV2Success(projectV2Data));
    yield put({ type: ProjectV2ActionTypes.FETCH_PROJECTV2S_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createProjectV2Failure(e));
  }
}

function* fetchProjectV2ByIdSaga(action: any): SagaIterator {
  try {
    // const { id } = action.payload;
    const response = yield call(fetchProjectV2ById, action.payload);
    const projectV2Data = response.data;
    yield put(fetchProjectV2ByIdSuccess(projectV2Data));
  } catch (e: any) {
    yield put(fetchProjectV2ByIdFailure(e));
  }
}

function* editProjectV2Saga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editProjectV2, id, data);
    const projectV2Data = response.data;
    yield put(editProjectV2Success(projectV2Data));
    yield put({ type: ProjectV2ActionTypes.FETCH_PROJECTV2_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editProjectV2Failure(e));
  }
}

function* watchProjectV2s() {
  yield takeEvery(ProjectV2ActionTypes.FETCH_PROJECTV2S_REQUEST, fetchProjectV2sSaga);
  yield takeEvery(ProjectV2ActionTypes.CREATE_PROJECTV2_REQUEST, createProjectV2Saga);
  yield takeEvery(ProjectV2ActionTypes.FETCH_PROJECTV2_BY_ID_REQUEST, fetchProjectV2ByIdSaga);
  yield takeEvery(ProjectV2ActionTypes.EDIT_PROJECTV2_REQUEST, editProjectV2Saga);
}

export default watchProjectV2s;
