import { ActionTypes } from "./constants";
import {
  fetchProjects,
  createProject,
  getProjectTasks,
  createProjectTask,
  updateProjectTask,
  getProjectById,
  getSingleTask,
  getAssignedTask,
  getProjectByUserId,
  fetchCountAssignedTasks,
  updateProject,
  getTaskCountBasedOnUser,
  projectMonthData,
  fetchProjectWithValue,
  fetchProjectWithOngoing,
  fetchProjectWithCompleted,
  fetchFunnelData,
  fetchProjectLoacation,
  fetchStatus,
  fetchProjectByLocation
} from "helpers";
import { put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  createProjectAPIResponse,
  createProjectTaskAPIResponse,
  deleteProjectError,
  deleteProjectResponse,
  projectApiError,
  projectApiResponse,
  updateProjectAPIResponse,
  fetchProjectAPIResponse,
  fetchProjectDetailAPIResponse,
  fetchProjectTaskAPIResponse,
  fetchTaskDetailAPIResponse,
  updateProjectTaskAPIResponse,
  fetchJobTypeAPIResponse,
  bulkUpdateProjectTaskStatusResponse,
  bulkUpdateProjectTaskStatusError,
} from "./actions";
import {
  batchUpdateProjectTask,
  deleteProject,
  fetchProjectStatus,
} from "helpers/api/projectManagement";

function* _projects(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjects, {
      page: params.payload.data.page,
      limit: params.payload.data.limit,
      sortBy: params.payload.data.sortBy,
      sort: params.payload.data.sort,
      filterBy: params.payload.data?.filterBy,
      searchBy: params.payload.data?.searchBy,
    });
    const projectData = response.data;
    if (!params.payload.data?.filterBy || params.payload.data?.filterBy === "month") {
      yield put(
        fetchProjectAPIResponse(
          ActionTypes.FETCH_PROJECT_LIST_SUCCESS,
          projectData
        )
      );
    } else {
      yield put(
        projectApiResponse(
          ActionTypes.FETCH_COMPLETE_PROJECT,
          projectData
        )
      );
    }
  } catch (error: any) {
    if (!params.payload.data?.filterBy || params.payload.data?.filterBy === "month") {
      yield put(
        fetchProjectAPIResponse(
          ActionTypes.FETCH_PROJECT_LIST_FAIL,
          error
        )
      );
    } else {
      // yield put(projectApiError(ActionTypes.FETCH_PROJECT, error));
    }
  }
}

function* _createProject({ payload }: any): SagaIterator {
  try {
    const response = yield call(createProject, {
      project: payload.data.project,
    });
    const projectData = response.data;
    yield put(
      createProjectAPIResponse(ActionTypes.CREATE_PROJECT_SUCCESS, projectData)
    );
  } catch (error: any) {
    yield put(createProjectAPIResponse(ActionTypes.CREATE_PROJECT_FAIL, error));
  }
}

function* _getProjectDetail({ payload }: any): SagaIterator {
  try {
    const response = yield call(getProjectById, payload.data);
    const projectData = response.data;
    yield put(
      fetchProjectDetailAPIResponse(ActionTypes.FETCH_PROJECT_DETAIL_SUCCESS, projectData)
    );
  } catch (error: any) {
    yield put(fetchProjectDetailAPIResponse(ActionTypes.FETCH_PROJECT_DETAIL_FAIL, error));
  }
}

function* _updateProject({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateProject, {
      id: payload.data.projectID,
      project: payload.data.project,
    });
    const projectTaskData = response.data;
    yield put(
      updateProjectAPIResponse(ActionTypes.UPDATE_PROJECT_SUCCESS, projectTaskData)
    );
  } catch (error: any) {
    yield put(updateProjectAPIResponse(ActionTypes.UPDATE_PROJECT_FAIL, error));
  }
}

function* projectValue(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjectWithValue, {
      filterBy: params.payload.data?.filterBy,
      filter: params.payload.data?.filter,
    });
    const projectData = response.data;
    yield put(
      projectApiResponse(ActionTypes.GET_PROJECTS_WITH_VALUE, projectData)
    );
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.GET_PROJECTS_WITH_VALUE, error));
  }
}

function* projectOnging(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjectWithOngoing, {
      filterBy: params.payload.data?.filterBy,
      filter: params.payload.data?.filter,
    });
    const projectData = response.data;
    yield put(
      projectApiResponse(
        ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE,
        projectData
      )
    );
  } catch (error: any) {
    yield put(
      projectApiError(ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE, error)
    );
  }
}

function* projectCompleted(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjectWithCompleted, {
      filterBy: params.payload.data?.filterBy,
      filter: params.payload.data?.filter,
    });
    const projectData = response.data;
    yield put(
      projectApiResponse(
        ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE,
        projectData
      )
    );
  } catch (error: any) {
    yield put(
      projectApiError(ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE, error)
    );
  }
}

function* funnelData(params: any): SagaIterator {
  try {
    const response = yield call(fetchFunnelData, {
      filterBy: params.payload.data.filterBy,
      filter: params.payload.data.filter,
    });
    const projectData = response.data;
    yield put(projectApiResponse(ActionTypes.GET_FUNNEL_DATA, projectData));
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.GET_FUNNEL_DATA, error));
  }
}

function* countBasedOnUser(params: any): SagaIterator {
  try {
    const response = yield call(getTaskCountBasedOnUser, {
      user: params.payload.data.user,
      filterBy: params.payload.data.filterBy,
      filter: params.payload.data.filter,
    });
    const data = response.data;
    yield put(
      projectApiResponse(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, data)
    );
  } catch (err: any) {
    yield put(
      projectApiResponse(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, err)
    );
  }
}

function* _fetchAssignedTask(params: any): SagaIterator {
  try {
    console.log("paramsact", params)
    const response = yield call(getAssignedTask, {
      id: params.payload.data.id,
      page: params.payload.data.page,
      limit: params.payload.data.limit,
      sortBy: params.payload.data.sortBy,
      sort: params.payload.data.sort,
      filterBy: params.payload.data.filterBy,
      userId: params.payload.data.userId,
      filter: params.payload.data.filter,
      searchBy: params.payload.data.searchBy,
      filterByStatus: params.payload.data.filterByStatus
    });
    const taskData = response.data;
    yield put(projectApiResponse(ActionTypes.FETCH_ASSIGNED_TASK, taskData));
  } catch (error: any) {
    //yield put(projectApiError(ActionTypes.FETCH_PROJECT, error));
  }
}

function* _countAssignedTask(params: any): SagaIterator {
  try {
    const response = yield call(fetchCountAssignedTasks, {
      user_id: params.payload.data.user_id,
      status: params.payload.data.status,
    });
    const countApprovalData = yield response.data;
    yield put(
      projectApiResponse(
        ActionTypes.FETCH_ASSIGNED_TASK_COUNT,
        countApprovalData
      )
    );
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.FETCH_ASSIGNED_TASK_COUNT, error));
  }
}

function* _createProjectTask({ payload }: any): SagaIterator {
  try {
    const response = yield call(createProjectTask, {
      projectID: payload.data.projectID,
      task: payload.data.task,
    });
    const projectTaskData = response.data;
    yield put(
      createProjectTaskAPIResponse(ActionTypes.CREATE_PROJECT_TASK_SUCCESS, projectTaskData)
    );
  } catch (error: any) {
    yield put(createProjectTaskAPIResponse(ActionTypes.CREATE_PROJECT_TASK_FAIL, error));
  }
}

function* _updateProjectTask({ payload }: any): SagaIterator {
  try {
    const response = yield call(updateProjectTask, {
      projectID: payload.data.projectID,
      taskID: payload.data.taskID,
      task: payload.data.task,
    });
    const projectTaskData = response.data;
    yield put(
      updateProjectTaskAPIResponse(
        ActionTypes.UPDATE_PROJECT_TASK_SUCCESS,
        projectTaskData
      )
    );
  } catch (error: any) {
    yield put(
      updateProjectTaskAPIResponse(ActionTypes.UPDATE_PROJECT_TASK_FAIL, error)
    );
  }
}

function* _getProjectTask({ payload }: any): SagaIterator {
  try {
    const response = yield call(getProjectTasks, {
      id: payload.data.id,
      sortBy: payload.data.sortBy,
      sort: payload.data.sort,
      userId: payload.data?.userId ?? '',
      jobType:payload.data?.jobType ?? ''
    });
    const singleData = response.data;
    yield put(
      fetchProjectTaskAPIResponse(ActionTypes.FETCH_SINGLE_PROJECT_TASK_SUCCESS, singleData)
    );
  } catch (error: any) {
    yield put(fetchProjectTaskAPIResponse(ActionTypes.FETCH_SINGLE_PROJECT_TASK_FAIL, error));
  }
}

function* _getProjects({ payload }: any): SagaIterator {
  try {
    const response = yield call(getProjectByUserId, {
      id: payload.data.id,
      page: payload.data.page,
      limit: payload.data.limit,
      sortBy: payload.data.sortBy,
      sort: payload.data.sort,
    });
    const singleData = response.data;

    yield put(
      projectApiResponse(ActionTypes.FETCH_CUSTOMER_PROJECT, singleData)
    );
    yield put(
      fetchProjectAPIResponse(ActionTypes.FETCH_PROJECT_LIST_SUCCESS, singleData)
    );
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.FETCH_CUSTOMER_PROJECT, error));
    yield put(fetchProjectAPIResponse(ActionTypes.FETCH_PROJECT_LIST_FAIL, error));
  }
}

function* _getSingleTask({ payload }: any): SagaIterator {
  try {
    const response = yield call(getSingleTask, {
      projectID: payload.data.projectID,
      taskID: payload.data.taskID,
    });
    const singleTaskData = response.data;
    yield put(
      fetchTaskDetailAPIResponse(ActionTypes.FETCH_SINGLE_TASK_SUCCESS, singleTaskData)
    );
  } catch (error: any) {
    yield put(fetchTaskDetailAPIResponse(ActionTypes.FETCH_SINGLE_TASK_FAIL, error));
  }
}

function* projectStatusList(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjectStatus, {
      data: params.payload.data,
    });
    const projectStatusData = response.data.data.values;
    console.log(projectStatusData);
    yield put(
      projectApiResponse(ActionTypes.FETCH_PROJECT_STATUS, projectStatusData)
    );
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.FETCH_PROJECT_STATUS, error));
  }
}

function* getProjectMonth(params: any): SagaIterator {
  try {
    const response = yield call(projectMonthData, {
      filterBy: params.payload.data?.filterBy,
      filter: params.payload.data?.filter,
    });
    const data = response.data;
    yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_MONTH_DATA, data));
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.FETCH_PROJECT_STATUS, error));
  }
}


function* _getProjectByLocation(params: any): SagaIterator {
  try {
    const response = yield call(fetchProjectByLocation, {
      filterBy: params.payload.data?.filterBy,
      filter: params.payload.data?.filter,
    });
    const data = response.data;
    yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_BY_LOCATION, data));
  } catch (error: any) {
    yield put(projectApiError(ActionTypes.FETCH_PROJECT_BY_LOCATION, error));
  }
}


function* _deleteProject(params: any): SagaIterator {
  try {
    const response = yield call(deleteProject, { id: params.payload.data.id });
    const enquiryData = yield response.data;
    yield put(
      deleteProjectResponse(ActionTypes.DELETE_PROJECT_SUCCESS, enquiryData)
    );
  } catch (e: any) {
    yield put(deleteProjectError(ActionTypes.DELETE_PROJECT_FAIL, e));
  }
}

function* _projectLocation(): SagaIterator {
  try {
    const response = yield call(fetchProjectLoacation);
    const projectData = response.data?.response?.projectLocation;
    yield put(
      projectApiResponse(ActionTypes.FETCH_PROJECT_LOCATION, projectData)
    );
  } catch (e: any) {
    yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_LOCATION, e));
  }
}

function* fetchProjectTaskStatus(): SagaIterator {
  try {
    const response = yield call(fetchStatus, "PROJECT_TASK_STATUS");
    const projectData = response.data?.data?.values;
    yield put(
      projectApiResponse(ActionTypes.FETCH_PROJECT_TASK_STATUS, projectData)
    );
  } catch (e: any) {
    yield put(projectApiResponse(ActionTypes.FETCH_PROJECT_TASK_STATUS, e));
  }
}

function* _fetchJobType(): SagaIterator {
  try {
    // const response = yield call(fetchStatus,"JOB_TYPE");
    const response = yield call(fetchStatus,"NEW_JOB_TYPE");
    const val = response.data.data.values;
  
    yield put(fetchJobTypeAPIResponse(ActionTypes.FETCH_JOB_TYPE_SUCCESS, val))
  } catch (error: any) {
    yield put(fetchJobTypeAPIResponse(ActionTypes.FETCH_JOB_TYPE_FAIL, error));
  }
}

function* bulkUpdateProjectTask(data: any): SagaIterator {
  try {
    console.log(data, "bulkdata")
    const response = yield call(batchUpdateProjectTask, { data });
    console.log(response, "responsebalue123")
    const val = response.data;
    console.log(val, "responsebalue")
    yield put(bulkUpdateProjectTaskStatusResponse(ActionTypes.BULK_UPDATE_PROJECT_TASK_SUCCESS, val))
  } catch (error: any) {
    yield put(bulkUpdateProjectTaskStatusError(ActionTypes.BULK_UPDATE_PROJECT_TASK_FAIL, error));
  }
}

function* watchProjectTask() {
  yield takeEvery(ActionTypes.FETCH_PROJECT_LIST, _projects);
  yield takeEvery(ActionTypes.CREATE_PROJECT, _createProject);
  yield takeEvery(ActionTypes.UPDATE_PROJECT, _updateProject);
  yield takeEvery(ActionTypes.FETCH_PROJECT_DETAIL, _getProjectDetail);
  yield takeEvery(ActionTypes.FETCH_SINGLE_PROJECT_TASK, _getProjectTask);
  yield takeEvery(ActionTypes.FETCH_SINGLE_TASK, _getSingleTask);
  yield takeEvery(ActionTypes.CREATE_PROJECT_TASK, _createProjectTask);
  yield takeEvery(ActionTypes.UPDATE_PROJECT_TASK, _updateProjectTask);
  yield takeEvery(ActionTypes.FETCH_ASSIGNED_TASK, _fetchAssignedTask);
  yield takeEvery(ActionTypes.FETCH_CUSTOMER_PROJECT, _getProjects);
  yield takeEvery(ActionTypes.FETCH_ASSIGNED_TASK_COUNT, _countAssignedTask);
  yield takeEvery(ActionTypes.FETCH_TASK_COUNT_BASED_ON_USER, countBasedOnUser);
  yield takeEvery(ActionTypes.FETCH_PROJECT_STATUS, projectStatusList);
  yield takeEvery(ActionTypes.FETCH_PROJECT_MONTH_DATA, getProjectMonth);
  yield takeEvery(ActionTypes.GET_PROJECTS_WITH_VALUE, projectValue);
  yield takeEvery(ActionTypes.GET_ONGOING_PROJECT_WITH_VALUE, projectOnging);
  yield takeEvery(ActionTypes.FETCH_PROJECT_BY_LOCATION, _getProjectByLocation)
  yield takeEvery(
    ActionTypes.GET_COMPLETE_PROJECT_WITH_VALUE,
    projectCompleted
  );
  yield takeEvery(ActionTypes.GET_FUNNEL_DATA, funnelData);
  yield takeEvery(ActionTypes.DELETE_PROJECT, _deleteProject);
  yield takeEvery(ActionTypes.FETCH_PROJECT_LOCATION, _projectLocation);
  yield takeEvery(
    ActionTypes.FETCH_PROJECT_TASK_STATUS,
    fetchProjectTaskStatus
  );
  yield takeEvery(ActionTypes.FETCH_JOB_TYPE, _fetchJobType);
  yield takeEvery(ActionTypes.BULK_UPDATE_PROJECT_TASK, bulkUpdateProjectTask);
}

export default watchProjectTask;
