import { put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { DPRActionTypes } from './constants';
import {
  createDPR,
  editDPR,
  fetchDPRById,
  fetchDPRs,
  fetchDPRsUsingProjectId
} from 'helpers/api/dpr';
import {
  createDPRFailure,
  createDPRRequest,
  createDPRSuccess,
  editDPRFailure,
  editDPRRequest,
  editDPRSuccess,
  fetchDPRByIdFailure,
  fetchDPRByIdRequest,
  fetchDPRByIdSuccess,
  fetchDPRsFailure,
  fetchDPRsRequest,
  fetchDPRsSuccess,
  fetchDPRsUsingProjectIdFailure,
  fetchDPRsUsingProjectIdSuccess,
  resetDPRSuccessState,
  resetSingleDPR
} from './actions';

function* fetchDPRsSaga(action: any): SagaIterator {
  try {
    const { page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchDPRs, page, limit, sortBy, sort, searchBy);
    const dprData = response.data;
    yield put(fetchDPRsSuccess(dprData));
  } catch (e: any) {
    yield put(fetchDPRsFailure(e));
  }
}
function* fetchDPRsUsingProjectIdSaga(action: any): SagaIterator {
  try {
    const {projectId, page, limit, sortBy, sort, searchBy } = action.payload || {};
    const response = yield call(fetchDPRsUsingProjectId,projectId, page, limit, sortBy, sort, searchBy);
    const dprData = response.data;
    yield put(fetchDPRsUsingProjectIdSuccess(dprData));
  } catch (e: any) {
    yield put(fetchDPRsUsingProjectIdFailure(e));
  }
}

function* createDPRSaga(action: any): SagaIterator {
  try {
    const { data } = action.payload;
    const response = yield call(createDPR, data);
    const dprData = response.data;
    yield put(createDPRSuccess(dprData));
    yield put({ type: DPRActionTypes.FETCH_DPRS_REQUEST, payload: { page: 1, limit: 15 } });
  } catch (e: any) {
    yield put(createDPRFailure(e));
  }
}

function* fetchDPRByIdSaga(action: any): SagaIterator {
  try {
    const response = yield call(fetchDPRById, action.payload);
    const dprData = response.data;
    yield put(fetchDPRByIdSuccess(dprData));
  } catch (e: any) {
    yield put(fetchDPRByIdFailure(e));
  }
}

function* editDPRSaga(action: any): SagaIterator {
  try {
    const { id, data } = action.payload;
    const response = yield call(editDPR, id, data);
    const dprData = response.data;
    yield put(editDPRSuccess(dprData));
    yield put({ type: DPRActionTypes.FETCH_DPR_BY_ID_REQUEST, payload: id });
  } catch (e: any) {
    yield put(editDPRFailure(e));
  }
}

function* watchDPRs() {
  yield takeEvery(DPRActionTypes.FETCH_DPRS_REQUEST, fetchDPRsSaga);
  yield takeEvery(DPRActionTypes.FETCH_DPRS_USING_PROJECTID_REQUEST, fetchDPRsUsingProjectIdSaga);
  yield takeEvery(DPRActionTypes.CREATE_DPR_REQUEST, createDPRSaga);
  yield takeEvery(DPRActionTypes.FETCH_DPR_BY_ID_REQUEST, fetchDPRByIdSaga);
  yield takeEvery(DPRActionTypes.EDIT_DPR_REQUEST, editDPRSaga);
}

export default watchDPRs;
