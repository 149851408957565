import { POActionTypes as ActionTypes } from './constants';

export const createPORequest = (data: any) => ({
  type: ActionTypes.CREATE_PO_REQUEST,
  payload: {data},
});

export const resetPOSuccessState = () => ({
  type: ActionTypes.RESET_PO_SUCCESS_STATE,
});

export const createPOSuccess = (response: any) => ({
  type: ActionTypes.CREATE_PO_SUCCESS,
  payload: response,
});

export const createPOFailure = (error: any) => ({
  type: ActionTypes.CREATE_PO_FAILURE,
  payload: error,
});

export const resetSinglePO = () => ({
  type: ActionTypes.RESET_SINGLE_PO,
});

export const fetchPOsRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_POS_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchPOsSuccess = (response: any) => ({
  type: ActionTypes.FETCH_POS_SUCCESS,
  payload: response,
});

export const fetchPOsFailure = (error: any) => ({
  type: ActionTypes.FETCH_POS_FAILURE,
  payload: error,
});

export const fetchPOByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_PO_BY_ID_REQUEST,
  payload: id,
});

export const fetchPOByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PO_BY_ID_SUCCESS,
  payload: response,
});

export const fetchPOByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_PO_BY_ID_FAILURE,
  payload: error,
});

export const editPORequest = (id: number, data: any) => ({
  type: ActionTypes.EDIT_PO_REQUEST,
  payload: { id, data },
});

export const editPOSuccess = (response: any) => ({
  type: ActionTypes.EDIT_PO_SUCCESS,
  payload: response,
});

export const editPOFailure = (error: any) => ({
  type: ActionTypes.EDIT_PO_FAILURE,
  payload: error,
});

export const fetchPOByProjectIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_PO_BY_PROJECT_ID_REQUEST,
  payload: id,
});

export const fetchPOByProjectIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PO_BY_PROJECT_ID_SUCCESS,
  payload: response,
});

export const fetchPOByProjectIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_PO_BY_PROJECT_ID_FAILURE,
  payload: error,
});

export const resetSingleProjectPO = () => ({
  type: ActionTypes.RESET_SINGLE_PROJECT_PO,
});