import { APICore } from "./apiCore";
const api = new APICore();

function createPaymentInvoice(data: any) {
    const baseUrl = `/payment/invoice`
    return api.create(`${baseUrl}`, { ...data })
}

function createPayment(data: any) {
    const baseUrl = `/payment/payment`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchPayments(id: any) {
    const url = `/payment/list/${id}`;
    return api.get(url, "");
}

function fetchOutgoingPayments(id: any) {
    const url = `/payment/outgoinglist/${id}`;
    return api.get(url, "");
}

function fetchMilestones() {
    const url = `/payment/milestones`;
    return api.get(url, "");
}

function fetchPaymentById(id: number) {
    const url = `/payment/${id}`;
    return api.get(url, "");
}

function editPayment(id: number, data: any) {
    const url = `/payment/${id}`;
    return api.update(url, data);
}

export { createPaymentInvoice, createPayment, fetchPaymentById, fetchPayments, fetchOutgoingPayments, fetchMilestones, editPayment };
