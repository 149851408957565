import { APICore } from "./apiCore";
const api = new APICore();

function createRfq(data: any) {
    const baseUrl = `/rfq/`
    return api.create(`${baseUrl}`, { ...data })
}
function fetchRfqs(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/rfq/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchRfqById(id: number) {
    const url = `/rfq/${id}`;
    return api.get(url, "");
}

function fetchRfqByProjectId(id: number) {
    const url = `/rfq/project/${id}`;
    return api.get(url, "");
}

function fetchRfqVendorByRfqId(id: number) {
    const url = `/rfq/rfqVendor/${id}`;
    return api.get(url, "");
}

function editRfq(id: number, data: any) {
    const url = `/rfq/${id}`;
    return api.update(url, data);
}


async function createRfqWithFiles(params: { rfqData: any, imageData?: any }) {
    try {
        const baseUrl = '/rfq';

        const createdRfq = await api.create(baseUrl, { ...params.rfqData });

        const rfqResponse = createdRfq.data;
        params.rfqData?.item_details?.forEach(async (item: any) => {
            await updateRfqItemWithFile({id:rfqResponse?.data?.newRfq?.id, rfqData:item, imageData:item.files})
        });

        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];

        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'RFQ');
            params.imageData.append('typeId', String(rfqResponse?.data?.newRfq?.id));

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.rfqData.image_id || []),
            ...newImageDataResponse
        ];
        if (newImageDataResponse.length > 0) {
            const updatedBody = {
                id: rfqResponse?.data?.newRfq?.id,
                body: {
                    ...rfqResponse?.data?.newRfq,
                    image_id: combinedImageIds,
                }
            };

            const baseRfqUrl = `/rfq/updateImage/${rfqResponse?.data?.newRfq?.id}`;
            const updateRfq = await api.update(baseRfqUrl, { ...updatedBody.body });

            return updateRfq;
        }

        return rfqResponse;


    } catch (error) {
        console.error('Error creating rfq with files:', error);
        throw error;
    }
}



async function updateRfqWithFiles(params: { id: number, rfqData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'RFQ');
            params.imageData.append('typeId', String(params.id));

            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.rfqData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/rfq/${params.id}`;
        return api.update(baseUrl, {
            ...params.rfqData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating RFQ with files:', error);
        throw error;
    }
}

async function updateRfqItemWithFile(params: { id: number, rfqData: any, imageData?:any}) {
    try {

        const baseUrl = `/rfq/rfqItem/${params.id}`;
        const rfqItem = await api.update(baseUrl, {
            ...params.rfqData,
        });

        console.log("params.rfqData", params.rfqData)
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        let uploadResponse = null;

        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(105));
            params.imageData.append('type', 'RFQ Item');
            params.imageData.append('typeId', String(rfqItem?.data?.data?.id));
            params.imageData.append('tagId', "55");


            const newImageFile = await api.create(fileUrl, params.imageData);
            uploadResponse = newImageFile?.data?.data?.response;

            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
            
            const combinedImageIds = [
                ...(params.rfqData.image_id || []),
                ...newImageDataResponse
            ];
    
            const updatedRfqItem = await api.update(baseUrl, {
                ...rfqItem?.data?.data,
                image_id: combinedImageIds, 
              });
            
            console.log(updatedRfqItem, "rfqItem");
            return {
                rfqItem: updatedRfqItem,
                uploadResponse
            };
        }


        console.log(rfqItem, "rfqItem");
        return {
            rfqItem,
            uploadResponse: null
        };
    } catch (error) {
        console.error('Error updating RFQ with files:', error);
        throw error;
    }
}
async function uploadFileWithImagesWithItem(rfqId: any, file: File) {
    try {
        const formData = new FormData();
        formData.append('rfqId', rfqId);
        formData.append('file', file);

        const response = await api.upload('/rfq/uploadFileWithImagesWithItem', formData);

        console.log(response,"response")

        if (response?.data?.success) {
            console.log(response.data.successMsg || 'File and images uploaded successfully.');
        } else {
            console.error(response?.data?.errorMsg || 'Failed to upload file and images.');
        }
    } catch (error) {
        console.error('An error occurred while uploading the file and images:', error);
        throw error;
    }
}


export { createRfq, fetchRfqById, fetchRfqs, editRfq, createRfqWithFiles, updateRfqWithFiles, fetchRfqVendorByRfqId , updateRfqItemWithFile, uploadFileWithImagesWithItem, fetchRfqByProjectId};
