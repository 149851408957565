export enum PaymentActionTypes {
    CREATE_PAYMENT_REQUEST = 'CREATE_PAYMENT_REQUEST',
    CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS',
    CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE',
    CREATE_PAYMENT_INVOICE_REQUEST = 'CREATE_PAYMENT_INVOICE_REQUEST',
    CREATE_PAYMENT_INVOICE_SUCCESS = 'CREATE_PAYMENT_INVOICE_SUCCESS',
    CREATE_PAYMENT_INVOICE_FAILURE = 'CREATE_PAYMENT_INVOICE_FAILURE',
    FETCH_PAYMENTS_REQUEST = 'FETCH_PAYMENTS_REQUEST',
    FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS',
    FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE',
    FETCH_OUTGOING_PAYMENTS_REQUEST = 'FETCH_OUTGOING_PAYMENTS_REQUEST',
    FETCH_OUTGOING_PAYMENTS_SUCCESS = 'FETCH_OUTGOING_PAYMENTS_SUCCESS',
    FETCH_OUTGOING_PAYMENTS_FAILURE = 'FETCH_OUTGOING_PAYMENTS_FAILURE',
    FETCH_MILESTONES_REQUEST = 'FETCH_MILESTONES_REQUEST',
    FETCH_MILESTONES_SUCCESS = 'FETCH_MILESTONES_SUCCESS',
    FETCH_MILESTONES_FAILURE = 'FETCH_MILESTONES_FAILURE',
    FETCH_PAYMENT_BY_ID_REQUEST = 'FETCH_PAYMENT_BY_ID_REQUEST',
    FETCH_PAYMENT_BY_ID_SUCCESS = 'FETCH_PAYMENT_BY_ID_SUCCESS',
    FETCH_PAYMENT_BY_ID_FAILURE = 'FETCH_PAYMENT_BY_ID_FAILURE',
    EDIT_PAYMENT_REQUEST = 'EDIT_PAYMENT_REQUEST',
    EDIT_PAYMENT_SUCCESS = 'EDIT_PAYMENT_SUCCESS',
    EDIT_PAYMENT_FAILURE = 'EDIT_PAYMENT_FAILURE',
    RESET_PAYMENT_SUCCESS_STATE = 'RESET_PAYMENT_SUCCESS_STATE',
    FETCH_SINGLE_PAYMENT = 'FETCH_SINGLE_PAYMENT',
    SET_SINGLE_PAYMENT = 'SET_SINGLE_PAYMENT',
    RESET_SINGLE_PAYMENT = 'RESET_SINGLE_PAYMENT',
}
