import { ProjectV2ActionTypes as ActionTypes } from './constants';

export const createProjectV2Request = (data: any) => ({
  type: ActionTypes.CREATE_PROJECTV2_REQUEST,
  payload: {data},
});

export const resetProjectV2SuccessState = () => ({
  type: ActionTypes.RESET_PROJECTV2_SUCCESS_STATE,
});

export const createProjectV2Success = (response: any) => ({
  type: ActionTypes.CREATE_PROJECTV2_SUCCESS,
  payload: response,
});

export const createProjectV2Failure = (error: any) => ({
  type: ActionTypes.CREATE_PROJECTV2_FAILURE,
  payload: error,
});

export const resetSingleProjectV2 = () => ({
  type: ActionTypes.RESET_SINGLE_PROJECTV2,
});

export const fetchProjectV2sRequest = (page: number, limit: number, sortBy: string,
  sort: string,
  searchBy: string) => ({
  type: ActionTypes.FETCH_PROJECTV2S_REQUEST,
  payload: { page, limit, sortBy, sort, searchBy },
});

export const fetchProjectV2sSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PROJECTV2S_SUCCESS,
  payload: response,
});

export const fetchProjectV2sFailure = (error: any) => ({
  type: ActionTypes.FETCH_PROJECTV2S_FAILURE,
  payload: error,
});

export const fetchProjectV2ByIdRequest = (id: number) => ({
  type: ActionTypes.FETCH_PROJECTV2_BY_ID_REQUEST,
  payload: id,
});

export const fetchProjectV2ByIdSuccess = (response: any) => ({
  type: ActionTypes.FETCH_PROJECTV2_BY_ID_SUCCESS,
  payload: response,
});

export const fetchProjectV2ByIdFailure = (error: any) => ({
  type: ActionTypes.FETCH_PROJECTV2_BY_ID_FAILURE,
  payload: error,
});

export const editProjectV2Request = (id: number, data: any) => ({
  type: ActionTypes.EDIT_PROJECTV2_REQUEST,
  payload: { id, data },
});

export const editProjectV2Success = (response: any) => ({
  type: ActionTypes.EDIT_PROJECTV2_SUCCESS,
  payload: response,
});

export const editProjectV2Failure = (error: any) => ({
  type: ActionTypes.EDIT_PROJECTV2_FAILURE,
  payload: error,
});
