import { APICore } from "./apiCore";
const api = new APICore();

function createProjectV2(data: any) {
    const baseUrl = `/projectV2/`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchProjectV2s(page: any, limit: any, sortBy?: string, sort?: string, searchBy?: string) {
    const baseURL = `/projectV2/`
    const params = new URLSearchParams();
    if (page !== undefined) params.append('page', page.toString());
    if (limit !== undefined) params.append('limit', limit.toString());
    if (sortBy) params.append('sortBy', sortBy);
    if (sort) params.append('sort', sort);
    if (searchBy) params.append('searchBy', searchBy);
    const url = `${baseURL}?${params.toString()}`;

    return api.get(url, "");
}

function fetchProjectV2ById(id: number) {
    const url = `/projectV2/${id}`;
    return api.get(url, "");
}

function editProjectV2(id: number, data: any) {
    const url = `/projectV2/${id}`;
    return api.update(url, data);
}
async function editProjectWithPoAttatchment(params: { id: number, projectData: any, imageData?: any }) {
    try {
        const fileUrl = `file/upload`;
        let newImageDataResponse: number[] = [];
        console.log("first", params.imageData)
        if (params.imageData instanceof FormData) {
            params.imageData.append('uploadedBy', String(params.id));
            params.imageData.append('type', 'PO Attatchment');
            params.imageData.append('typeId', String(params.id));
            params.imageData.append('tagId', "40");


            const newImageFile = await api.create(fileUrl, params.imageData);
            const newImageFileResponse = newImageFile.data;
            newImageDataResponse = (newImageFileResponse?.data?.response?.fileIds || []).map((id: string) => Number(id));
        }

        const combinedImageIds = [
            ...(params.projectData.image_id || []),
            ...newImageDataResponse
        ];

        const baseUrl = `/projectV2/${params.id}`;
        return api.update(baseUrl, {
            ...params.projectData,
            image_id: combinedImageIds
        });
    } catch (error) {
        console.error('Error updating Project with files:', error);
        throw error;
    }
}
function runProjectV2MRP(id: number) {
    const url = `/bom/project/run-mrp/${id}`;
    return api.get(url, "");
}

function createProjectV2Indents(id: number, data: any) {
    const baseUrl = `/bom/project/create-indents/${id}`
    return api.create(`${baseUrl}`, { ...data })
}

function fetchProjectBomById(id: number, type: string) {
    const url = `/bom/project/${type}/${id}`;
    return api.get(url, "");
}

function fetchProjectProcesses(id: any) {
    const url = `/projectV2/processes/${id}`;
    return api.get(url, "");
}

function fetchProjectBomProcesses(id: any, data:any) {
    const url = `/bom/project/processes/${id}`;
    return api.create(url, { ...data });
}

function fetchProjectBomTableById(id: number) {
    const url = `/bom/project/bom-table/${id}`;
    return api.get(url, "");
}

export { createProjectV2, fetchProjectV2ById, fetchProjectV2s, editProjectV2, runProjectV2MRP, createProjectV2Indents, fetchProjectBomById, editProjectWithPoAttatchment, fetchProjectProcesses, fetchProjectBomTableById, fetchProjectBomProcesses };
